import React, { useEffect } from "react";
import SEO from "../components/layout_elements/SEO";
import { motion } from "framer-motion";
import { slowEase } from "../styles/animations/framer_transitions";
import ExperienceCard from "../components/ExperienceCard";
import { graphql, useStaticQuery } from "gatsby";
import Carousel from "../components/carousel/Carousel";
import PageLanding from "../components/PageLanding";
import { BsLinkedin } from "react-icons/bs";

const Resume = () => {
  const {
    allContentfulWorkExperience: { nodes: experiences },
    allContentfulTechnology: { nodes: technologies },
  } = useStaticQuery(graphql`
    query getWorkExperiences {
      allContentfulWorkExperience(sort: { fields: [startDate], order: DESC }) {
        nodes {
          id
          companyName
          startDate(formatString: "MMMM YYYY")
          endDate(formatString: "MMMM YYYY")
          websiteUrl
          logo {
            publicUrl
            file {
              url
            }
          }
          location
          jobTitle
        }
      }
      allContentfulTechnology {
        nodes {
          id
          name
          logo {
            publicUrl
            file {
              url
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Resume | Zach Mumbauer" description="" />
      <PageLanding>My Experience</PageLanding>
      <h2 className="font-body uppercase text-2xl text-center mb-10 select-none">
        Work Experience
      </h2>
      <div className="grid grid-cols-1 grid-rows-1 sm:grid-cols-2 gap-20 place-items-center first:col-span-2 mb-40">
        {experiences.map(
          ({
            companyName,
            startDate,
            endDate,
            location,
            logo,
            jobTitle,
            id,
          }: IExperience) => {
            return (
              <ExperienceCard
                key={id}
                companyName={companyName}
                jobTitle={jobTitle}
                startDate={startDate}
                endDate={endDate}
                location={location}
                logo={logo}
              />
            );
          }
        )}
      </div>
      <Carousel>
        {technologies.map((technology: Technology, index: Number) => {
          const { name, logo } = technology;
          return (
            <img
              className="h-10 block mx-20 brightness-0 dark:invert grayscale select-none"
              draggable={false}
              src={logo.file.url}
              alt={name}
              key={index.toString()}
            />
          );
        })}
      </Carousel>

      <p className="font-body text-center my-10 select-none">
        For a complete list of my credentials, please visit my{" "}
        <a
          href="https://www.linkedin.com/in/zmumbauer/"
          className="link"
          target="_blank"
        >
          LinkedIn
        </a>
      </p>
    </>
  );
};

interface IExperience {
  companyName: string;
  jobTitle: string;
  startDate: string;
  endDate?: string;
  logo: any;
  location: string;
  id: string;
}

interface Technology {
  id: string;
  name: string;
  logo: {
    publicUrl: string;
    file: {
      url: string;
    };
  };
}

export default Resume;
