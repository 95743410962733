import { motion } from "framer-motion";
import { Link } from "gatsby";
import React from "react";
import slugify from "slugify";

const customSlugify = (text) => {
  return slugify(text.replace(/PayPal/gi, "pay pal"), { lower: true });
};

const ExperienceCard = ({
  companyName,
  jobTitle,
  startDate,
  endDate,
  logo,
  location,
}: IProps) => {
  const slug = customSlugify(companyName);
  return (
    <>
      <Link
        to={`/${slug}`}
        className="font-display link w-full flex flex-col flex-1 h-full place-content-center border border-current"
      >
        <div className="w-full p-5 sm:p-10 select-none antialiased">
          <div className="flex flex-col sm:flex-row sm:flex-nowrap mb-2">
            <div className="mb-5 sm:mb-0 w-1/6">
              <CompanyLogo companyName={companyName} logo={logo} />
            </div>
            <div className="ml-5 w-5/6">
              <h3 className="font-display text-3xl font-bold mb-2">
                {companyName}
              </h3>
              <h4 className="font-body uppercase">{jobTitle}</h4>
              <p className="font-body italic">
                {startDate} to {endDate ? endDate : "Present"}
              </p>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

const CompanyLogo = ({
  companyName,
  logo,
}: {
  companyName: string;
  logo: any;
}) => {
  return (
    <div className="grid place-items-center w-full h-full">
      <motion.img
        className="w-full brightness-0 dark:invert grayscale"
        src={logo.file.url}
        alt={`${companyName} logo`}
      />
    </div>
  );
};

interface IProps {
  companyName: string;
  jobTitle: string;
  startDate: string;
  endDate?: string;
  logo: any;
  location: string;
}

export default ExperienceCard;
